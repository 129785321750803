/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import CampHoc from '../../components/CampHoc';

// eslint-disable-next-line react/prop-types
const HaltinVaellus = ({ data }) => {
  const seo = {
    url: 'https://www.discoverlapland.fi/vaellukset/haltin-vaellus',
    image: `https://www.discoverlapland.fi${data.imageSEO.childImageSharp.gatsbyImageData.images.fallback.src}`,
    title: 'Haltin Vaellus',
    description:
      'Upeiden keväthankien aikaan Suomen korkeimpien tuntureiden juurilla, hiihtäen hiljaa Suomen huipulle. Haltin hiihtovaelluksen ajankohta on 30.4.-7.5.2023 ja mukaan mahtuu seitsemän talviretkeilykokemusta omaavaa naista.',
  };

  return (
    <CampHoc
      heroImage={data.campBg.childImageSharp.gatsbyImageData}
      seo={seo}
      camp="haltin-vaellus"
    >
      <div className="camp-text-content">
        <h2>Haltin vaellus</h2>
        <h3>28.8.-2.9.2023</h3>
        <p className="bolded">
          Lähde kanssamme valloittamaan Käsivarren erämaahan Suomen korkein
          kohta Halti. Syksyinen Haltin vaellus on niin upea kokemus, että
          kylmät väreet vipeltävät pitkin kehoasi, vatsanpohjaa kutkuttaa ja
          sydän meinaa pakahtua. Ja mikä parasta, irtaudut ulkomaailmasta
          vaelluksen ajaksi, sillä puhelimissa ei ole kuuluvuutta kuin vasta
          lähempänä vaelluksen loppua.
        </p>
        <br />
        <p>
          Vaellusreitti on uskomattoman upea ja matkan varrella on paljon eri
          luonnonnähtävyyksiä, joita jäämme ihailemaan: Jännittäviä
          vesiputouksia, pyhiä järviä sekä tuntureita, tunturierämaan eläimiä ja
          kasveja unohtamatta. Syksyllä on myös varsin hyvät mahdollisuudet
          nähdä revontulia iltaisin. Reitti itsessään koostuu monesta puron ja
          jokien ja purojen ylityksistä, kivikkoisesta sekä louhikkoista
          reitistä, matkalla on myös vähän helpohkoa polkua sekä nousua ja
          laskua. Suurin nousu tapahtuu noin 1324 metrin korkeudessa olevalle
          Suomen-puoleiselle Haltin huipulle neljäntenä päivänä sään salliessa.
          Vaelluksen päivämatkat ovat 12-20 km välillä ja koko reitin pituudeksi
          tulee noin 115km.
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg1.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg3.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p className="bolded">Vaelluksen aikataulu ja vaativuus:</p>
        <p>
          Vaellus kestää kuusi päivää ja viisi yötä, jotka nukutaan
          varaustuvissa. Lähdemme maanantaiaamuna kohti Haltia ja takaisin
          Kilpisjärvellä olemme lauantaina alkuillasta. Tämä vaellus sopii
          hyväkuntoisille, vaellus- sekä retkeilytaitoja omaaville naisille.
          Vaellukselle otetaan enintään seitsemän naista, minimäärä vaelluksen
          toteutumiseen on neljä. Tämä retki ei ole sovi ensimmäiseksi pitkäksi
          vaellukseksi.
        </p>
        <br />
        <p className="bolded">Vaelluksen opas</p>
        <p>
          Oppaana vaelluksella toimii eräopas Sanni Lampinen. Muoniossa asuva
          Sanni on toiselta ammatiltaan fysioterapeutti ja hän toimii oppaana
          myös Discover Laplandin hiihtovaelluksilla. Sanni on kiinnostunut
          ihmisen kokonaisvaltaisesta hyvinvoinnista ja siitä, miten voimme
          luonnossa eheyttää itseämme. Retkeilijänä Sanni on nautiskelija ja
          seikkailija. Hän nauttii ympäröivästä luonnosta, sen kauniista
          yksityiskohdista, rauhasta, eri sääolosuhteista ja kaikesta
          erilaisuudesta mitä luonto meille tarjoaa.
        </p>
        <br />
        <p className="bolded">Ennen retkeä</p>
        <p>
          Lähetämme sinulle tarkan pakkauslistan, jonka käymme läpi retkelle
          lähtijöiden kanssa. Annamme tietoa ja vinkkejä juuri tähän
          vaellukseen, ruokailuun, vaatetukseen sekä tuvissa yöpymiseen, jotta
          sinulla on turvallinen sekä luotettava olo lähteä vaellukselle.
          Retkellä on myös oma salainen Facebook-ryhmä, jossa osallistujat
          voivat kysellä mm. kimppakyytejä / kimppamajoituksia.
        </p>
        <br />
        <p className="bolded">Mitä tarvitset mukaasi vaellukselle?</p>
        <p>
          - Vaellukseen sekä eri säihin sopivat vaatteet ja vaelluskengät <br />
          - Makuupussi / makuupussin sisälakana tuvassa nukkumiseen - Rinkka{' '}
          <br /> - Ruuantekovälineet / ruokatermos, ruokaa, vesipullo /
          termospullo <br /> - Kävely/vaellussauvat
        </p>
      </div>
      <GatsbyImage
        image={data.campImg2.childImageSharp.gatsbyImageData}
        className="offering-img"
        alt="image"
      />
      <div className="camp-text-content">
        <br />
        <p className="bolded">Vaelluksen hinta on 820€ (sis alv.)</p>
        <br />
        <p>
          Hintaan kuuluu: <br /> Pakkauslista, päiväkohtainen retkisuunnitelma
          ja turvallisuussuunnitelma <br /> Opas kuudeksi päiväksi <br /> Ennen
          retkeä: Osallistujan kanssa retkelle valmistautuminen
          verkkotapaamisissa (6.7.2023 klo 19-21 ja 24.8.2023 klo 19-21), joissa
          käymme läpi tarvittavat vaellustarvikkeet, vaatetus, ruoka sekä sen
          laitto sekä tarvittava apu Kilpisjärvelle saapumiseen ja
          majoittautumiseen.
        </p>
        <br />
        <p>
          <span className="bolded">
            Ilmoittaudu mukaan sähköpostitse{' '}
            <a href="mailto: anettelatvapiikkila@gmail.com">
              anettelatvapiikkila@gmail.com
            </a>{' '}
            ja toimi näin:
          </span>
          <br />
          OTSIKKO: Haltin vaellus <br />
          Vastaa meilissä ao. kysymyksiin: <br />
          Koko nimi: <br />
          Ikä: <br />
          Puhelinnumero: <br />
          Paikkakunta: <br />
          Mikä on aikaisempi vaelluskokemuksesi? <br />
          Mikä on kuntotasosi eli kuinka paljon ja miten liikut normaalisti
          arjessa? <br />
          Mikä on terveydentilasi (onko polvi/nivelongelmia, astmaa jne.)?{' '}
          <br />
          <br />
          Sinulle tulee itsellesi kustannettavaksi omat kuljetukset
          Kilpisjärvelle, yöpymiset Kilpisjärvellä ennen retkeä tai retken
          jälkeen. <br />
        </p>
      </div>
      <div className="offering-img-row">
        <GatsbyImage
          image={data.campImg4.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
        <GatsbyImage
          image={data.campImg5.childImageSharp.gatsbyImageData}
          className="offering-img-vert"
          alt="image"
        />
      </div>
      <div className="camp-text-content">
        <p>
          <span className="bolded">Retken varaus- ja peruutusehdot:</span>{' '}
          <br />
          Kun olet ilmoittautunut mukaan sähköpostitse, saat paluupostina
          materiaalikululaskun 250€. Kun lasku on maksettu, on paikka
          vaellukselle varattu ja saat paluupostina vaelluksen materiaalit;
          pakkauslistan ja retkisuunnitelman sekä onlinetapaamisten linkit.
        </p>
        <br />
        <p>
          Vaelluksen loppulaskun 570€ saat sähköpostiisi vaelluksen
          aloituspäivänä, jolloin lasku tulee maksaa ja lähettää tästä
          kuittikopio osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          .
        </p>
        <br />
        <p>
          Jos perut osallistumisesi vaellukselle missä kohtaa tahansa, pidätämme
          toimitetut vaelluksen materiaalien kulut sekä toimistokulut 250€.
          Ilmoittautumisen peruutus tulee tehdä sähköpostitse osoitteeseen{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>
          . Lue lisää varaus – ja peruutusehdoistamme{' '}
          <Link className="bolded" to="/varaus-ja-peruutusehdot">
            tästä
          </Link>
          .
          <br />
          <br />
          Pidäthän huolen, että sinulla on voimassa oleva vapaa-ajan- tai
          matkavakuutus vaelluksen aikana. <br /> <br />
          Tekisikö mieli mukaan tälle ainutlaatuiselle vaellukselle? Mikäli
          sinulla on kysyttävää vaelluksesta, voit olla yhteydessä Anetteen joko
          meilitse{' '}
          <a className="bolded" href="mailto: anettelatvapiikkila@gmail.com">
            anettelatvapiikkila@gmail.com
          </a>{' '}
          tai soittamalla 040 772 4124.
          <br />
          <br />
          Tervetuloa mukaan tekemään unohtumaton Haltin valloitus ja Käsivarren
          erämaan naisten vaellus!
        </p>
      </div>
    </CampHoc>
  );
};

export const query = graphql`
  {
    campBg: file(relativePath: { eq: "images/hikes/Hike-Halti-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg1: file(relativePath: { eq: "images/hikes/halti-2023-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg2: file(relativePath: { eq: "images/hikes/halti-2020-6.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg3: file(relativePath: { eq: "images/hikes/halti-2023-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg4: file(relativePath: { eq: "images/hikes/halti-2023-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    campImg5: file(relativePath: { eq: "images/hikes/halti-2023-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    imageSEO: file(relativePath: { eq: "images/hikes/Hike-Halti-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1366, layout: FIXED)
      }
    }
  }
`;

export default HaltinVaellus;
